var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"\n        text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4\n        success--text\n        d-inline\n      "},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"success","text":"","icon":"","large":""},on:{"click":_vm.RedirectSyllabus}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(_vm.teacher.user)?_c('span',[_vm._v("Учебный план "+_vm._s(_vm.teacher.user.first_name)+" "+_vm._s(_vm.teacher.user.last_name))]):_vm._e(),(
          _vm.syllabus.length * 1.25 < 100 &&
          this.$store.getters.profile.user_access_level == 'training_officer'
        )?_c('v-btn',{staticClass:"complete_btn",attrs:{"to":{
          name: 'SyllabusDetails',
          params: { id: _vm.teacher.id, sid: _vm.syllabus.length + 1 },
        },"color":"success","x-small":_vm.$vuetify.breakpoint.xs,"small":_vm.$vuetify.breakpoint.sm}},[_vm._v("заполнять")]):_vm._e()],1),_c('div',[(
          this.$store.getters.profile.user_access_level == 'training_officer'
        )?_c('v-progress-linear',{attrs:{"value":_vm.syllabus.length * 1.25,"color":"success","stream":"","height":"25"}},[_c('strong',{staticClass:"white--text"},[_vm._v(_vm._s((_vm.syllabus.length / 80) * 100)+"% законченный")])]):_vm._e()],1),_c('v-row',{staticClass:"mt-2"},_vm._l((_vm.syllabus),function(s){return _c('v-col',{key:s.id,attrs:{"cols":"6","xs":"6","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-card',{attrs:{"link":"","to":{
            name: 'SyllabusDetails',
            params: { id: _vm.teacher.id, sid: s.number },
          },"elevation":"6","rounded":"lg"}},[_c('v-card-title',[_vm._v(" "+_vm._s(s.number)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(s.title)+" ")])],1)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }