<template>
  <div>
    <div class="d-block">
      <div
        class="
          text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4
          success--text
          d-inline
        "
      >
        <v-btn
          color="success"
          text
          icon
          class="mr-3"
          @click="RedirectSyllabus"
          large
        >
          <v-icon>mdi-arrow-left</v-icon> </v-btn
        ><span v-if="teacher.user"
          >Учебный план {{ teacher.user.first_name }}
          {{ teacher.user.last_name }}</span
        >
        <v-btn
          :to="{
            name: 'SyllabusDetails',
            params: { id: teacher.id, sid: syllabus.length + 1 },
          }"
          color="success"
          :x-small="$vuetify.breakpoint.xs"
          :small="$vuetify.breakpoint.sm"
          class="complete_btn"
          v-if="
            syllabus.length * 1.25 < 100 &&
            this.$store.getters.profile.user_access_level == 'training_officer'
          "
          >заполнять</v-btn
        >
      </div>
      <div>
        <v-progress-linear
          v-if="
            this.$store.getters.profile.user_access_level == 'training_officer'
          "
          :value="syllabus.length * 1.25"
          color="success"
          stream
          height="25"
        >
          <strong class="white--text"
            >{{ (syllabus.length / 80) * 100 }}% законченный</strong
          >
        </v-progress-linear>
      </div>
      <v-row class="mt-2">
        <v-col
          cols="6"
          xs="6"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          v-for="s in syllabus"
          v-bind:key="s.id"
        >
          <v-card
            link
            :to="{
              name: 'SyllabusDetails',
              params: { id: teacher.id, sid: s.number },
            }"
            elevation="6"
            rounded="lg"
          >
            <v-card-title>
              {{ s.number }}
            </v-card-title>
            <v-card-subtitle>
              {{ s.title }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { TEACHER_GET } from "@/store/actions/teacher";
import { SYLLABUS_GET } from "@/store/actions/syllabus";

export default {
  data() {
    return {
      syllabus: [],
      teacher: {},
    };
  },
  methods: {
    async GetSyllabus() {
      let resp = await this.$dispatch(SYLLABUS_GET, this.$route.params.id);
      this.syllabus = resp.result;
    },
    async GetTeacher() {
      let resp = await this.$dispatch(TEACHER_GET, this.$route.params.id);
      this.teacher = resp.result;
    },
    RedirectSyllabus() {
      history.back();
    },
  },
  created() {
    this.GetSyllabus();
    this.GetTeacher();
  },
};
</script>

<style scoped>
.complete_btn {
  float: right !important;
}
</style>